import styled from 'styled-components';
import BgImage from 'common/src/assets/image/saasModern/map.png';

export const Wrapper = styled.section`
  width: 960px;
  margin: 0 auto;
  height: 100%;
  font-size: 16px;
  text-align: justify;
  background-color: white;
  padding: 80px;
  line-height: 1.8;
  color: #646464;
`;

export const Content = styled.div`
  width: 960px;
  margin: 0 auto;
  height: 100%;
  font-size: 16px;
  text-align: justify;
  background-color: white;
  padding: 80px;
  line-height: 1.8;
  color: #646464;
`;

export const Title = styled.h1`
  font-size: 38px;
  letter-spacing: 0.02em;
`;

export default { Wrapper, Content, Title };
