import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';

import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import SEO from '../../../components/seo';
import { GlobalStyle, ContentWrapper } from '../sassModern.style';
import { Wrapper, Content, Title } from './terms.style';

const Privacy = () => (
  <ThemeProvider theme={saasModernTheme}>
    <Fragment>
      <SEO title="Terms & Conditions" />

      <ResetCSS />
      <GlobalStyle />

      <Wrapper>
        <Content>
          <div className="panel">
            <div className="panel-body staticPage" id="privacy">
              <div className="">
                <Title>Privacy Policy</Title>

                <h2>Your data</h2>
                <p>
                  All the information that you provide is strictly confidential
                  and we will never, under any circumstances, share your
                  personal information with a third-party, except if legally
                  required to do so to the appropriate authorities. <br />
                </p>

                <h2>Google user data</h2>
                <p>
                  The application will use the permission
                  https://www.googleapis.com/auth/contacts.readonly to show your
                  contacts in the guest list screen to allow users to import
                  their contacts directly. Using this functionality will display
                  to the user the contacts with their firstname, lastname, email
                  address, mobile phone and address. Those data are not stored
                  in our servers, only the contacts that the user decides to
                  import will be stored in our server. The contacts imported are
                  not shared and are strictly confidential as mentioned in the
                  above paragraph.
                </p>

                <h2>General recommendations</h2>
                <p>
                  Your profile and data are protected by password to ensure that
                  only you can access them. We recommend that you do not
                  communicate this information to anyone. You alone are
                  responsible for maintaining the confidentiality of your
                  password and/or any other information related to your profile.
                  <br /> Furthermore, you should always remember to disconnect
                  from your profile and close the window after your session,
                  particularly if you use a shared computer. This will avoid
                  other users being able to access your personal data.
                </p>

                <h2>Security</h2>
                <p>
                  We are committed to ensuring that your information is secure.
                  In order to prevent unauthorised access or disclosure we have
                  put in place suitable physical, electronic and managerial
                  procedures to safeguard and secure the information we collect
                  online.
                </p>
              </div>
            </div>
          </div>
        </Content>
      </Wrapper>
    </Fragment>
  </ThemeProvider>
);

export default Privacy;
